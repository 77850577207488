section.sessions-search-result {
  margin: 30px 20px;
  width: auto;
  min-height: auto;
  overflow-y: auto;
  font-family: "Montserrat";
  font-size: 15px;

  pre {
    display: inline;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
  }
}

.search-wrapper {
  padding-right: 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}