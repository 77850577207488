@import '../../../../../src/styles/palette.scss';

.card-wrapper {
  width: 199px;
  height: 312px;
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  justify-content: flex-end;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

  &-featured {
    width: 100%;
    min-height: 200px;
    height: max-content;
    margin-bottom: 2rem;
    height: max-content;
    box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.16);
  }

  .card-wrapper__buttons {
    .session-meeting-title-join-btn {
      width: 73px;
    }
  
    .select-box--container {
      width: auto;
      margin-right: 4px;
    }

    &--not-featured {
      .select-box--items {
        top: -130px;
        left: -170px;
      }
    }
  }
}

.editable-session--table-row-left-date {
  font-weight: 500;
}