@import "~tailwindcss";
@import 'styles/_app.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* RESET TAILWIND PREFLIGHT STYLES */
  h2 {
    @extend .text-2xl;
    font-weight: bold;
  }
  h3 {
    @extend .text-xl;
    font-weight: bold;
  }
  h4 {
    @extend .text-lg;
    font-weight: bold;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  blockquote {
    margin: 0;
    padding: 0 1em;
    color: $light-gray-blue;
    border-left: .25em solid $gray;
  }

  ul, ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol { list-style-type: decimal; }
  ul { list-style-type: disc; }
  ul ul { list-style-type: circle; }
  ul ul ul { list-style-type: square; }

  li {
    display: list-item;
    text-align: match-parent;
  }
}

.toast-message {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  justify-content: center;
  padding: 40px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  @media (max-width: 500px) {
    overflow: auto;
  }
}

