@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.alice-carousel {
  & > div {
    margin: 10px;
  }

  .alice-carousel__wrapper {
    .alice-carousel__stage {
      padding: 15px 5px;
    }
  }
}

.alice-carousel__next-btn {
  top: 0;
  width: 55px;
  right: -45px;
  cursor: pointer;
  position: absolute;
  transform: translateY(150%);
}

.alice-carousel__prev-btn {
  top: 0;
  left: -45px;
  width: 55px;
  cursor: pointer;
  position: absolute;
  transform: translateY(150%);
}

.alice-carousel__play-btn-item {
  border: 1px solid #000;
}

.alice-carousel__next-btn:has(.next-button--disabled),
.alice-carousel__prev-btn:has(.prev-button--disabled) {
  pointer-events: none;
}