section {
  width: 100%;
  overflow-y: scroll;
  height: max-content;
  min-height: calc(100vh - 96px);
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.content-wrapper  {
  @media (max-width: 1082px) {
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    & > div {
      align-items: center;
      flex-direction: column;
    }
  }
}

.event-details {
  width: 58%;
  .date-range {
    display: flex;
    justify-content: left;
  } 
  @media (max-width: 1082px) {
    width: 90%;
    background-color: #fff;
    .date-range {
      justify-content: center;
    } 
    & > img {
      margin: 0 auto;
    }
    & > div {
      align-items: center;
      & p {
        text-align: center;
      }
    }
  }
}
.connection-details-wrapper {
  width: 40%;
  @media (max-width: 1082px) {
    width: 100%;
  }
  @media (min-width: 767px)  {
    & > div {
      & .organization-name + .select-date {
        display: none;
      }
    } 
    & > div {
      & .formatted-date + .select-date {
        display: block;
      }
    }
  }
  @media (max-width: 767px)  {
    & .separate-line {
      display: none;
    }
    & > div {
      padding: 1 rem;
      & .organization-name + .select-date {
        display: block;
      }
    }
    & > div {
      & .formatted-date + .select-date {
        display: none;
      }
    }
  }
}


.content-box {
  & div, span, p {
    background-color: transparent !important;
  }
}

.show-more-button {
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
}

.branding-bg-img {
  height: 45vh;
  object-fit: cover;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.button-box {
  @media (max-width: 640px)  {
    width: 100%;
    & > button {
      width: 100%;
    }
  }
}
