.session-meeting__content {
  .session-meeting-title-join-btn {
    width: 73px;

    &-expanded {
      height: 34px;
      line-height: 1;
    }
  }

  .select-box--container {
    width: auto;
    margin-right: 2px;
  }
}

.session-meeting-title-add-rm-btn {
  padding: 10px;
  margin-left: 15px;
}

.session-meeting-title-join-btn {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 0;
}

.session-meeting--table-cell-title-not-expanded {
  padding: 10px;
  h3 {
    font-weight: normal;
  }
}

.session-meeting--table-cell-image-expanded {
  width: 100%;
  height: 160px;
  img {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
  }
}

.session-meeting--table-cell-image-not-expanded {
  width: 100%;
  height: 110px;
  img {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
  }
}

.session-meeting--table-cell-topic {
  padding-top: 10px;
}