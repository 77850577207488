.editable-welcome--wrapper {
  padding: 40px;
}

.editable-welcome--title {
  margin-top: 0;
  margin-left: 20px;
  margin-bottom: 40px;
}

.editable-welcome--description {
  margin: 30px 20px 30px 20px;
}