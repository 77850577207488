@import '../../../../../src/styles/palette.scss';

.hover-label-btn {
  span:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span:last-child {
    display: none;
  }
  &:hover {
    span:first-child {
      display: none;
    }
    span:last-child {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.remove-btn-hover:hover {
  background: rgba($dark-off-black, 0.75);
}

.not-joined:hover {
  fill: $white;
  color: $white;
  background: var(--primary-color);
}

.timing-column {
  width: 170px;
  min-width: 170px;
  @media (max-width: 768px) {
    min-width: unset;
  }
}
.session-btn {
  display: flex;
  align-items: baseline;
  .common-btn.opacity-60.outline-auto-visible {
    &:focus-visible {
      outline: auto;
      outline-color: black;
      opacity: 1;
      svg {
        opacity: 0.8;
      }
    }
  }
}

.pagination-btn {
  margin-top: 2px;
  margin-right: 5px;
}

.general-sessions-arrow-buttons {
  .common-btn.opacity-20.outline-auto-visible {
    &:focus-visible {
      outline: auto;
      outline-color: black;
      opacity: 1;
      svg {
        opacity: 0.2;
      }
    }
  }
}

.alice-carousel__wrapper {
  .cancel-button {
    position: relative;
    top: 3px;
  }
}